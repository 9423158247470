.chat-component {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 96px);
  width: 100%;

  .bot-info {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom-width: 1px;
    border-color: rgba(0, 0, 0, .1);
    color: rgb(52, 53, 65);
    gap: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    .ant-card-meta-avatar {
      width: 2.5rem;
      height: 2.5rem;
      flex-shrink: 0;
    }

    .ant-card-meta-title {
      font-weight: 600;
    }
  }

  .bot-message-list {
    flex-grow: 1;
    margin-bottom: 1rem;
  }

  .dynamic-textarea {
    width: 48rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .chat-component {
    .bot-info {
      background-color: rgb(247, 247, 248);
    }
    .dynamic-textarea {
      width: 95%;
    }
  }
}