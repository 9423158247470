.bot-workdesk {
  .jarvis-header {
    display: none !important;
  }
  margin: 0;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: rgb(245, 245, 245);

  .bot-workdesk-sider {
    height: 100%;
    overflow-y: auto;
  }

  .bot-workdesk__main {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;

    .chat-component {
      height: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .bot-workdesk {
    flex-direction: column;
  }
}