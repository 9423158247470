.auth-user-info {
  .user-info {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    user-select: none;

    .username {
      font-size: 1rem;
      margin-left: 4px;
    }
  }

  &.active {
    background-color: #1677ff;
  }
}