.contact-us {
  h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 1px solid #d9d9e3;
    padding: 2rem;
    border-radius: 8px;
    background-color: #f2f2f2;
  }

  .contact-methods {
    padding: 0 2rem;
    .contact-method {
      .title {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      button {
        background-color: #00BF12;
      }
    }
  }
}