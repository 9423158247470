.bot-market {
  .bot-list {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .bot-card {
      flex: 0 1 calc((100% - 2rem)/3);
    }
  }
}

@media screen and (max-width: 576px) {
  .bot-market {
    .bot-list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bot-card {
        width: 30rem;
      }
    }
  }
}