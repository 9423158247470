.jarvis-header {
  padding-inline: 0;
  .content-wrapper {
    display: flex;
    align-items: center;

    .logo-wrapper {
      display: flex;
      align-items: center;
      img {
        width: 3rem;
        height: 3rem;
        margin: 0;
        border-radius: 50%;
      }
      .logo-text {
        font-size: 1.2rem;
        font-weight: 600;
        margin-left: 8px;
        color: #FFF;
      }
    }

    .menu {
      font-size: 1rem;
      margin-left: 2rem;
    }

    .auth-user-info {
      margin-left: auto;
    }
  }
}

.mobile-header-container {
  .trigger-btn-container {
    position: fixed;
    right: 0.5rem;
    bottom: 10%;
    z-index: 1000;
    .trigger-btn {
      width: 4rem;
      height: 4rem;
    }
  }
}
.mobile-header {
  .ant-drawer-body {
    background-color: #001529;
    display: flex;
    padding: 0;
    flex-direction: column;
    height: 100vh;
    .logo-wrapper {
      display: flex;
      align-items: center;
      padding: 1rem;
      img {
        width: 3rem;
        height: 3rem;
        margin: 0;
        border-radius: 50%;
      }
      .logo-text {
        font-size: 1.2rem;
        font-weight: 600;
        margin-left: 8px;
        color: #FFF;
      }
    }
    .menu {
      font-size: 1rem;
      margin-top: 2rem;
      li {
        border-radius: 0;
      }
    }
    .auth-user-info {
      margin-top: auto;
      padding: 1rem 0;
      .login-btn {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}