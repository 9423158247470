.user-dashboard {
  .greeting {
    font-size: 2rem;
    margin: 15px 0.5px;
  }

  .session-record-pane,
  .bot-record-pane {
    margin-bottom: 0.5rem;
  }

  .session-record-pane {
    .sessions-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .session-card {
        flex: 0 0 calc((100% - 2rem)/4);
        border: 1px solid rgb(240, 240, 240);
        border-radius: 8px;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);

        a {
          color: inherit;

          .updated-at {
            font-size: 0.5rem;
            color: gray;
          }
        }
      }
    }
  }

  .bot-record-pane {
    .bot-list {
      display: flex;
      gap: 1.5rem;

      .bot-card {
        flex: 0 0 calc((100% - 1.5rem)/3);

        .bot-info {
          .bot-name {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .user-dashboard {
    .greeting {
      display: none;
      font-size: 1.5rem;
      padding-left: 2rem;
    }

    .session-record-pane {
      margin-top: 0.5rem;

      .sessions-container {
        .session-card {
          flex: 0 0 calc((100% - 1rem)/2);
        }
      }
    }

    .bot-record-pane {
      .bot-list {
        flex-direction: column;
        gap: 0.5rem;

        .bot-card {
          width: 100%;
        }
      }
    }
  }
}