.bot-card {
  width: 18rem;
  cursor: pointer;
  display: flex;
  border: 1px solid #d9d9e3;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 1rem;

  // Transition effect for smooth scaling
  transition: transform 0.3s ease-in-out;

  // Hover effect to scale the bot-card
  &:hover {
    transform: scale(1.05);
  }
  .bot-avatar {
    width: 4rem;
    img {
      width: 100%;
    }
  }
  .bot-info {
    flex: 1;
    height: 4rem;
    .bot-name {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: inline-flex;
      align-items: center;
    }
    .bot-description {
      font-size: 0.8rem;
      color: #666;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      transform: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .bot-card {
    .comming-soon-tag {
      display: flex;
      align-items: center;
    }
  }
}