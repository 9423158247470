$border-color: hsla(0, 0%, 100%, .2);

@keyframes flash {
  0% {
    background-color: hsla(0, 0%, 100%, .4);
  }
}

.bot-workdesk-sider {
  width: 260px;
  background-color: rgb(32, 33, 35);
  overflow-x: hidden;
  color: #fff;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  .bot-workdesk-sider__header {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .bot-name {
      flex-grow: 1;
      border: 1px solid $border-color;
      border-radius: 6px;
      font-size: 0.875rem;
      padding: 0 0.75rem;
      line-height: 2.5rem;
      align-items: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: inline-block;  /* 或者设置为 block，这是为了让 width 属性生效 */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .bot-avatar {
        width: 1rem;
      }

      a {
        padding: 0 0.5rem;

        &:hover {
          background-color: rgb(52, 53, 55);
        }

        color: inherit;
      }
    }

    .new-session-btn {
      height: 2.75rem;
      border-color: $border-color;
      background-color: rgb(32, 33, 35);
      color: #fff;
      transition-duration: .2s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1);

      &:hover {
        background-color: rgb(52, 53, 55);
      }
    }
  }

  .bot-workdesk-sider__sessions {
    font-size: 0.875rem;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    overflow-y: auto;
    // 滚动条无背景色
    scrollbar-color: transparent transparent;

    .ant-empty {
      margin-top: 2rem;
    }

    li.session-row {
      &.new {
        a {
            animation: flash 3s steps(60, start);
          }
      }

      a {
        color: inherit;
        display: flex;
        align-items: center;
        padding: 0.75rem 2.5rem 0.75rem 0.75rem;
        border-radius: 0.375rem;
        gap: 0.75rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;

        .anticon {
          font-size: 1.25rem;
        }

        .session-info {
          .updated-at {
            font-size: 0.5rem;
          }
        }

        .session-operations {
          position: absolute;
          display: flex;
          right: 0.25rem;
          visibility: hidden;
        }

        &:hover {
          background-color: rgba(42, 43, 50, 1);

          .session-operations {
            visibility: visible;
          }
        }
      }

      &.active {
        a {
          background-color: rgba(52, 53, 65, 1);

          .session-operations {
            visibility: visible;
          }
        }
      }
    }
  }

    .auth-user-info {
      border-top: 1px solid hsla(0, 0%, 100%, .2);
      padding: 0.5rem 0;

      .login-btn-container {
        .login-btn {
          visibility: hidden;
        }
      }
    }
}

.mobile-sider-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(52, 53, 65);
  height: 3.5rem;
  align-items: center;
  padding-left: 0.3rem;
  color: #fff;

  .new-session-btn, .trigger-btn {
    color: #fff;
  }

  .session-name {
    flex: 1 1 0%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    line-height: 1.5rem;
    padding-top: 2px;
  }
}

@media screen and (max-width: 575px) {
  .bot-workdesk-sider {
    width: 100%;
    height: 100%;
    .ant-empty {
      svg {
        width: 10rem;
      }
    }
  }
}