@keyframes blink {
  0% {background-color: black;}
  50% {background-color: transparent;}
  100% {background-color: black;}
}

.bot-message-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  .bot-message {
    display: flex;
    justify-content: center;

    .content-section {
      width: 48rem;
      display: flex;
      align-items: flex-start; // align avatar and content to the top
      min-height: 60px; // set a minimum height for each message
      padding: 1.5rem;
      border-bottom-width: 1px;
      border-color: rgba(0, 0, 0, .1);
      color: rgb(52, 53, 65);
      gap: 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;

      .bot-message-avatar {
        .user-avatar {
          width: 2rem;
          height: 2rem;
          border-radius: 0;
        }

        .bot-avatar {
          width: 2rem;
        }
      }
    }

    &:before,
    &:after {
      border: 0 solid #d9d9e3;
      box-sizing: border-box;
    }

    &.user {
      background-color: rgb(255, 255, 255)
    }

    &.bot {
      background-color: rgb(247, 247, 248);
    }


    .bot-message-content {
      width: 100%;
      overflow-wrap: break-word; // prevent long words from breaking the layout

      .user-content {
        white-space: pre-wrap;
      }

      .block-code {
        border-radius: 0.5rem;
        width: 100%;
      }

      .inline-code {
        --tw-prose-code: #111827;
        color: var(--tw-prose-code);
        font-size: .875em;
        font-weight: 600;

        &::before,
        &::after {
          content: '`';
          border: 0 solid #d9d9e3;
          box-sizing: border-box;
        }
      }
    }
  }

  .message-end {
    margin-top: 2rem;
  }

  .cursor {
    display: inline-block;
    width: 10px;
    height: 1.2rem;
    vertical-align: middle;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@media screen and (max-width: 576px) {
  .bot-message-list {
    .bot-message {
      width: 100%;

      .content-section {
        width: 100%;

        .bot-message-content {
          width: calc(100% - 3rem);
        }
      }
    }
  }
}