html {
  font-size: 16px;
}

@media (max-width: 1199px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 12px;
  }
}