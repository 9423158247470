$textarea-line-height: 24px;

.dynamic-textarea {
  position: sticky;
  bottom: 20px;
  width: 100%;
  padding-top: 3rem;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 54.73%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .stop-generation-btn {
    width: 10rem;
    margin: 0.5rem;
  }

  .textarea-container {
    --tw-shadow: 0 0 15px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 0 15px var(--tw-shadow-color);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
    padding: 1rem;
    border-width: 1px;
    border-radius: 0.75rem;
    width: 100%;
    background-color: white;

    .textarea {
      line-height: 24px;
      padding-right: 3rem;
      padding-left: 0;
      border: 0;

      &:focus {
        border: 0;
        outline: none;
        box-shadow: none;
      }
    }

    .send-button {
      position: absolute;
      bottom: 1rem;
      right: 0.75rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: grey;
      padding: 0.5rem;
      transition-duration: .15s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1);
      border-radius: 0.375rem;

      .anticon-send {
        font-size: 1rem;
      }

      &.active {
        background-color: rgb(22, 119, 255);
        color: white;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    &.disabled {
      cursor: not-allowed;
      display: flex;
      justify-content: center;
      opacity: 0.4;

      textarea {
        background-color: transparent;
      }
    }
  }
}